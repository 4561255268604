import React from "react"
import styled from "styled-components"

function WeeklyCatchup() {
  return (
    <Wrapper>
      <Title>WeeklyCatchup</Title>
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Title = styled.h1``

export default WeeklyCatchup
